import request from '@/utils/request';

// 产品分类
export function materialsClassificationList(params) {
  return request({ url: `/materials_categories/`, method: 'get', params })
}

export function materialsClassificationCreate(data) {
  return request({ url: `/materials_categories/`, method: 'post', data })
}

export function materialsClassificationUpdate(data) {
  return request({ url: `/materials_categories/${data.id}/`, method: 'put', data })
}

export function materialsClassificationDestroy(data) {
  return request({ url: `/materials_categories/${data.id}/`, method: 'delete', data })
}

export function materialsClassificationSpecList(params) {
  return request({ url: `/materials/categories_spec/`, method: 'get', params })
}

// 产品信息
export function materialsInformationList(params) {
  return request({ url: `/materials/`, method: 'get', params })
}

export function materialsInformationCreate(data) {
  return request({ url: `/materials/`, method: 'post', data })
}

export function materialsInformationUpdate(data) {
  return request({ url: `/materials/${data.id}/`, method: 'put', data })
}

export function materialsInformationDestroy(data) {
  return request({ url: `/materials/${data.id}/`, method: 'delete', data })
}

// 物料属性
export function materialsAttrList(params) {
  return request({ url: `/categories_attr/`, method: 'get', params })
}

export function materialsAttrCreate(data) {
  return request({ url: `/categories_attr/`, method: 'post', data })
}

export function materialsAttrUpdate(data) {
  return request({ url: `/categories_attr/${data.id}/`, method: 'put', data })
}

export function materialsAttrDestroy(data) {
  return request({ url: `/categories_attr/${data.id}/`, method: 'delete', data })
}

// 产品单位
export function materialsUnitList(params) {
  return request({ url: `/materials_units/`, method: 'get', params })
}

export function materialsUnitCreate(data) {
  return request({ url: `/materials_units/`, method: 'post', data })
}

export function materialsUnitUpdate(data) {
  return request({ url: `/materials_units/${data.id}/`, method: 'put', data })
}

export function materialsUnitDestroy(data) {
  return request({ url: `/materials_units/${data.id}/`, method: 'delete', data })
}